import React, {useEffect, useState}                                       from "react";
import {useTranslation}                                                   from "react-i18next";
import {useNavigate}                                                      from "react-router-dom";
import {ReviewRating, ReviewItem}                                         from "entities/reviews";
import {SkeletonTabs, SkeletonLayout}                                     from "entities/skeleton";
import {ReviewFilterTypes}                                                from "services/reviews/types";
import {SpecialistProfileReviewItem, SpecialistProfileTabsItem, Nullable} from "shared/model";
import {ButtonType, ButtonColor, Tabs, ButtonDefault}                     from "shared/ui";
import {AppMode}                                                          from "store/features";
import {fetchReviews}                                                     from "./lib/useFetchReviews";
import styles                                                             from "./styles.module.scss";


interface Props {
  id: Nullable<number>;
  appMode: Nullable<string>;
  isViewMode?: boolean;
  reviews?: SpecialistProfileReviewItem;
  isLoading?: boolean;
}

const i18n_prefix = "page.specialist.profile.reviews."
export default function SpecialistProfileReviews({
  id:      specialistId,
  reviews: propReviews,
  appMode,
  isViewMode = false,
  isLoading,
}: Props) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {getReviews, reviewsFromTab} = fetchReviews({appMode});

  const [tabs, setTabs] = useState<SpecialistProfileTabsItem[]>([]);
  const [reviews, setReviews] = useState<Nullable<SpecialistProfileReviewItem>>(null);
  const [tabId, setTabId] = useState<Nullable<number>>(null);
  const [stars, setStars] = useState<number[]>([]);

  const handleGetReviewsClick = (id: Nullable<number>) => {
    if (appMode === AppMode.SPECIALIST && specialistId) return;
    if (isViewMode) return;

    if (!id) {
      if (appMode === AppMode.SPECIALIST) {
        getReviews({filter: ReviewFilterTypes.DATE_DESC});
      } else {
        getReviews({filter: ReviewFilterTypes.DATE_DESC, specialist_id: specialistId as number});
      }
    } else {
      if (appMode === AppMode.SPECIALIST) {
        getReviews({service_id: id, filter: ReviewFilterTypes.DATE_DESC});
      } else {
        getReviews({
          service_id:    id,
          filter:        ReviewFilterTypes.DATE_DESC,
          specialist_id: specialistId as number,
        });
      }
    }
    setTabId(id);
  }

  const handleNavigateToReviews = () => {
    if (appMode === AppMode.SPECIALIST) {
      if (!tabId) {
        return navigate(`/specialist-self-reviews/`);
      }
      return navigate(`/specialist-self-reviews/${tabId}`);
    }
    if (appMode === AppMode.CLIENT) {
      if (!tabId) {
        return navigate(`/specialist-reviews/${specialistId}`);
      }
      return navigate(`/specialist-reviews/${specialistId}/${tabId}`);
    }
  };

  useEffect(() => {
    if (reviewsFromTab) {
      setStars([
        reviewsFromTab.reviews_overview.star5,
        reviewsFromTab.reviews_overview.star4,
        reviewsFromTab.reviews_overview.star3,
        reviewsFromTab.reviews_overview.star2,
        reviewsFromTab.reviews_overview.star1,
      ]);
      setReviews({
        dashboard: reviewsFromTab.reviews_overview,
        data:      reviewsFromTab.reviews,
        tabs:      [],
      })
    }
  }, [reviewsFromTab]);

  useEffect(() => {
    if (propReviews) {
      setReviews(propReviews);
      setTabs(propReviews.tabs);
      setStars([
        propReviews?.dashboard.star5 ?? 0,
        propReviews?.dashboard.star4 ?? 0,
        propReviews?.dashboard.star3 ?? 0,
        propReviews?.dashboard.star2 ?? 0,
        propReviews?.dashboard.star1 ?? 0,
      ]);
      setTabId(propReviews.tabs.find((tab) => tab.is_selected)?.id as number);
    }
  }, [propReviews]);

  return <div className={styles.wrapper}>
    {isLoading
      ? <SkeletonLayout height={22} width={130} borderRadius={6} theme="dark" />
      : <span className={styles.header}>{t(`${i18n_prefix}title`)}</span>}

    {isLoading
      ? <SkeletonTabs />
      : <div className={styles.tabs}>
        {tabs.map((tab, index) => <Tabs
          key={tab.id ?? index}
          onClick={() => handleGetReviewsClick(tab.id)}
          activeTab={tabId === tab.id}
        >
          {`${tab.text} (${tab.count})`}
        </Tabs>)}
      </div>}

    {isLoading
      ? <SkeletonLayout height={102} />
      : <ReviewRating
        rate={reviews?.dashboard.rate ?? 0}
        nmbReviews={reviews?.dashboard.nmb_reviews ?? 0}
        stars={stars}
      />}

    {isLoading ?
      Array.from({length: 2}, (_, i) => <SkeletonLayout key={i} height={179} />)
      : reviews?.data?.slice(0, 2).map((review) => {
        return <ReviewItem
          key={review.id}
          name={review.name ?? ""}
          date={review.created_at ?? ""}
          rating={review.rate ?? 0}
          review_text={review.text ?? ""}
          media={review.media ?? []}
          price={review.sum}
          isApproved={review.is_approved ?? false}
          isViewMode={false}
        />
      })}

    {!reviews?.data?.length && <span className={styles.empty}>
      {appMode === AppMode.CLIENT && <>
        {reviews?.tabs.length === 1
          ? t(`${i18n_prefix}empty.client.none`)
          : t(`${i18n_prefix}empty.client.with_work`)}
      </>}
      {appMode === AppMode.SPECIALIST && t(`${i18n_prefix}empty.specialist.none`)}
    </span>}

    {(appMode === AppMode.SPECIALIST && specialistId) || isViewMode || (reviews?.tabs.length === 1 && !reviews?.data)
      ? null
      : <>
        {isLoading
          ? <SkeletonLayout height={46} theme="dark" />
          : <ButtonDefault
            buttonColor={ButtonColor.GRAY}
            buttonType={ButtonType.WRAPPED}
            onClick={handleNavigateToReviews}
          >
            {t(`${i18n_prefix}button`)}
          </ButtonDefault>}
      </>}
  </div>
};