import * as React                          from "react";
import {FC, useEffect, useState}           from "react";
import {
  PurchaseItem,
  useFreezeTariffInitMutation,
  useFreezeTariffMutation,
  useGetFreezeTariffInfoMutation,
  useSubscribeInitMutation,
  useUnfreezeTariffMutation,
}                                          from "services/specialists/tariffs";
import {SpecialistPaymentInformationCard}  from "components/SpecialistPaymentInformationCard";
import {ButtonDefault, ButtonTwoLines}     from "shared/ui";
import {SkeletonLayout}                    from "entities/skeleton";
import {InAppLayout}                       from "entities/general";
import {useAppDispatch, useAppSelector}    from "store";
import {
  addNotification,
  PurchaseType,
  selectLocale,
  selectTariffDays,
  setFreezeWaiting,
  setInAppInfoIsOpen,
  setPurchaseFreezeUnfreezeIsLoading,
}                                          from "store/features";
import {useTranslation}                    from "react-i18next";
import {useNativeHandler, useOpenPayments} from "shared/lib";
import {NativeActions, ButtonRenderType}   from "shared/model";


interface Props {
  purchaseItems?: PurchaseItem[];
  isLoading?: boolean;
  refetch?: () => void;
}

const i18n_prefix = "page.specialist.tariffs.info."
const TariffPaymentCards: FC<Props> = ({
  purchaseItems,
  isLoading,
  refetch = () => {
  },
}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const unfreezeDays = useAppSelector(selectTariffDays);
  const currentLocale = useAppSelector(selectLocale);

  const [getFreezeInfo, {data: freezeInfoResponse}] = useGetFreezeTariffInfoMutation();
  const [freezeTariff, {data: freezeTariffResponse}] = useFreezeTariffMutation();
  const [freezeTariffWithPrice, {data: freezeTariffWithPriceResponse}] = useFreezeTariffInitMutation();
  const [unfreezeTariff, {
    data:      unfreezeTariffResponse,
    isLoading: unfreezeTariffIsLoading,
  }] = useUnfreezeTariffMutation();
  const [subscribeInit, {
    data:      subscribeInitResponse,
    isLoading: subscribeInitIsLoading,
  }] = useSubscribeInitMutation();

  const [openFreezeInApp, setOpenFreezeInApp] = useState(false);
  const [openUnfreezeInApp, setOpenUnfreezeInApp] = useState(false);
  const [purchaseId, setPurchaseId] = useState<number>();

  const handleToggleFreezeInApp = () => {
    setOpenFreezeInApp(prev => !prev);
    dispatch(setInAppInfoIsOpen(!openFreezeInApp));
  };

  const handleToggleUnfreezeInApp = () => {
    setOpenUnfreezeInApp(prev => !prev);
    dispatch(setInAppInfoIsOpen(!openUnfreezeInApp));
  };

  const handleFreezeTariffClick = () => {
    const freezeData = freezeInfoResponse?.content.info;
    if (!freezeData) return;
    if (!purchaseId) return;
    if (!freezeData.price) {
      return freezeTariff({purchase_id: purchaseId, ...freezeData});
    }
    return freezeTariffWithPrice({id: purchaseId, days: freezeData.days});
  }

  const handleTabButtonClick = (id: number, type: ButtonRenderType) => {
    setPurchaseId(id);
    switch (type) {
      case ButtonRenderType.TARIFF_FREEZE:
        return getFreezeInfo(id);
      case ButtonRenderType.TARIFF_UNFREEZE:
        return handleToggleUnfreezeInApp();
      case ButtonRenderType.TARIFF_SUBSCRIBE_ERROR:
        return subscribeInit({id, is_now: true});
    }
  }

  const handleUnfreezeTariffClick = () => {
    if (purchaseId) {
      unfreezeTariff(purchaseId);
      dispatch(setFreezeWaiting(false));
    }
  }

  const handleDataRefetch = () => {
    dispatch(setPurchaseFreezeUnfreezeIsLoading(true));
    window.setTimeout(() => {
      refetch();
      dispatch(setPurchaseFreezeUnfreezeIsLoading(false));
    }, 2000);
  }

  useEffect(() => {
    if (freezeInfoResponse && freezeInfoResponse.content && freezeInfoResponse.content.info) {
      handleToggleFreezeInApp();
    }
  }, [freezeInfoResponse]);

  useEffect(() => {
    if (unfreezeTariffResponse && unfreezeTariffResponse.status) {
      handleToggleUnfreezeInApp();
      dispatch(addNotification({icon: "check", text: t("page.specialist.tariffs.notification_unfreeze")}));
      handleDataRefetch();
    }
  }, [dispatch, unfreezeTariffResponse, refetch]);

  useEffect(() => {
    if (freezeTariffResponse && freezeTariffResponse.status) {
      handleToggleFreezeInApp();
      handleDataRefetch();
    }
  }, [freezeTariffResponse, refetch, freezeTariffWithPriceResponse]);

  useEffect(() => {
    if (freezeTariffWithPriceResponse && freezeTariffWithPriceResponse.status) {
      handleToggleFreezeInApp();
    }
  }, [freezeTariffWithPriceResponse, refetch])

  useOpenPayments({
    paymentId: freezeTariffWithPriceResponse?.content.payment_id,
    type:      PurchaseType.TARIFF_FREEZE,
  });

  useOpenPayments({
    paymentId: subscribeInitResponse?.content.payment_id,
    type:      PurchaseType.TARIFF_SUBSCRIBE_ERROR,
  });

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (openFreezeInApp) {
      return setOpenFreezeInApp(false);
    }
    if (openUnfreezeInApp) {
      return setOpenUnfreezeInApp(false);
    }
  });

  // useEffect(() => {
  //   if (subscribeInitResponse?.content.payment_id) {
  //     handleToggleFreezeInApp();
  //   }
  // }, [subscribeInitResponse]);

  const freezeTitle = currentLocale === "kk"
    ? t(`${i18n_prefix}freeze.title`, {count: freezeInfoResponse?.content.info.days})
    : t(`${i18n_prefix}freeze.title.value.keyWithCount`, {count: freezeInfoResponse?.content.info.days});

  const freezeDescription = currentLocale === "kk"
    ? `${t(`${i18n_prefix}freeze.description.part_1`, {count: freezeInfoResponse?.content.info.days_left})}
    ${t(`${i18n_prefix}freeze.description.part_2`, {date: freezeInfoResponse?.content.info.freeze_at})}
    `
    : `${t(`${i18n_prefix}freeze.description.part_1.value.keyWithCount`, {count: freezeInfoResponse?.content.info.days_left})} ${t(`${i18n_prefix}freeze.description.part_2`, {date: freezeInfoResponse?.content.info.freeze_at})}`

  const unfreezeDescription = currentLocale === "kk"
    ? t(`${i18n_prefix}unfreeze.description`, {count: unfreezeDays ? +unfreezeDays : 0})
    : t(`${i18n_prefix}unfreeze.description.value.keyWithCount`, {count: unfreezeDays ? +unfreezeDays : 0});


  return <>
    {(!purchaseItems?.length || isLoading)
      ? <SkeletonLayout height={148} />

      : purchaseItems?.map((card, index) =>
        <SpecialistPaymentInformationCard
          type="tariff"
          key={index}
          color={card.color}
          title={card.title}
          subtitle={card.subtitle}
          description={card.description}
          buttons={card?.buttons}
          isLoading={unfreezeTariffIsLoading || subscribeInitIsLoading}
          onClick={handleTabButtonClick}
        />,
      )}
    {freezeInfoResponse?.content &&
      <InAppLayout
        isOpen={openFreezeInApp}
        title={freezeTitle}
        description={freezeDescription}
        img="/illustrations/getting-enough-sleep.svg"
        onClose={handleToggleFreezeInApp}
      >
        {!freezeInfoResponse.content.info.price
          ? <ButtonDefault
            hasSpace
            onClick={handleFreezeTariffClick}
          >
            {t(`${i18n_prefix}buttons.freeze`)}
          </ButtonDefault>

          : <ButtonTwoLines
            hasSpace
            onClick={handleFreezeTariffClick}
            title={t(`${i18n_prefix}buttons.freeze`)}
            subtitle={t(`${i18n_prefix}buttons.pay`, {price: freezeInfoResponse.content.info.price})}
          />}
      </InAppLayout>}

    <InAppLayout
      isOpen={openUnfreezeInApp}
      title={t(`${i18n_prefix}unfreeze.title`)}
      img="/illustrations/deadline.svg"
      description={unfreezeDescription}
      onClose={handleToggleUnfreezeInApp}
    >
      <ButtonDefault
        loading={unfreezeTariffIsLoading}
        hasSpace
        onClick={handleUnfreezeTariffClick}
      >
        {t(`${i18n_prefix}buttons.unfreeze`)}
      </ButtonDefault>
    </InAppLayout>
  </>
};

export default TariffPaymentCards;