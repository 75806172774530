import React, {useEffect, useState}                                          from "react";
import {useNavigate}                                                         from "react-router-dom";
import {useTranslation}                                                      from "react-i18next";
import {ButtonColor, ButtonType, Icon, ButtonDefault, Label, GalleryWrapper} from "shared/ui";
import {
  selectAvatarIsStartUpload,
  Nullable,
  selectProfile,
  NativeActions,
  ImageResizeMode,
  setAvatarIsStartUpload,
  setProfile,
}                                                                            from "shared/model";
import {formatRate, useNativeHandler, setActionForNative, imageUrl}          from "shared/lib";
import {SkeletonLayout}                                                      from "entities/skeleton";
import {InAppLayout}                                                         from "entities/general";
import {AppMode, selectLocale}                                               from "store/features";
import {useAppDispatch, useAppSelector}                                      from "store";
import {useFakeProgressBar}                                                  from "./lib/useFakeProgressBar";
import cn                                                                    from "classnames";
import styles                                                                from "./AvatarBlock.module.scss";
import {useUploadAvatarMutation}                                             from "shared/api";
import {Item}                                                                from "react-photoswipe-gallery";
import {ImageWithPreloader}                                                  from "shared/ui/ImageWithPreloader";


interface Props {
  appMode: Nullable<string>;
  isViewMode?: boolean;
  labels?: { title: string; style: string }[];
  isLoading?: boolean;
}

const IMG_SIZE = 120 * 2;
const i18n_prefix = "page.specialist.profile.avatar."
export default function AvatarBlock({labels, appMode, isViewMode = false, isLoading}: Props) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const avatarIsStartUpload = useAppSelector(selectAvatarIsStartUpload);
  const profile = useAppSelector(selectProfile);
  const locale = useAppSelector(selectLocale);

  const [newAvatar, setNewAvatar] = useState<Nullable<string>>(null);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [uploadFinished, setUploadFinished] = useState(false);

  const [uploadAvatar] = useUploadAvatarMutation();

  const {progress, completeProgress} = useFakeProgressBar({isSuccess: uploadFinished, isStart: avatarIsStartUpload});

  const handleChangeAvatarClick = () => navigate("/profile/avatar-change");

  const toggleUploadFailedInApp = () => setUploadFailed(prev => !prev);

  const handleTryUploadAvatarAgain = () => {
    setActionForNative(NativeActions.OPEN_AVATAR_UPLOAD);
    setUploadFailed(false);
  }

  const reviewsCount = locale === "ru"
    ? `(${t(`${i18n_prefix}rating.value.keyWithCount`, {count: profile?.content.specialist?.nb_reviews})})`
    : t(`${i18n_prefix}rating`, {count: profile?.content.specialist?.nb_reviews});

  useEffect(() => {
    if (uploadFinished && completeProgress) {
      dispatch(setAvatarIsStartUpload(false));
    }
  }, [uploadFinished, completeProgress]);

  const handleUpdateProfile = (avatar: string) => {
    dispatch(setProfile({
      ...profile,
      content: {
        ...profile?.content,
        //@ts-ignore
        specialist: {
          ...profile?.content.specialist,
          avatar:           avatar,
          avatar_moderated: false,
        },
      },
    }));
  }

  useEffect(() => {
    if (uploadFinished && completeProgress && newAvatar) {
      handleUpdateProfile(newAvatar);
      uploadAvatar({digest: newAvatar});

    }
  }, [uploadFinished, completeProgress, newAvatar]);

  useNativeHandler<{ digest: string }>("profile", NativeActions.AVATAR_UPLOAD_FINISHED, (data) => {
    if (data.digest) {
      setNewAvatar(data.digest);
      setUploadFinished(true);
    }
  });

  useNativeHandler("profile", NativeActions.AVATAR_UPLOAD_FAILED, () => {
    toggleUploadFailedInApp();
    dispatch(setAvatarIsStartUpload(false));
  });

  useNativeHandler("profile", NativeActions.AVATAR_UPLOAD_STARTED, () => {
    return dispatch(setAvatarIsStartUpload(true));
  });

  return <div className={styles.info_holder}>
    <div className={styles.info_content_wrapper}>
      <div className={cn(styles.info_content_photo, {[styles.client_view]: appMode === AppMode.CLIENT || isViewMode})}>
        {isLoading ? <SkeletonLayout width={120} height={120} borderRadius={60} theme="dark" /> : <>
          {profile?.content.specialist?.avatar
            ? <>
              <GalleryWrapper>
                <GalleryMediaItem
                  digest={profile?.content.specialist?.avatar}
                  size={IMG_SIZE}
                />
              </GalleryWrapper>
            </>
            : <div className={styles.svg_avatar_holder}>
              <img src={"/images/user.svg"} width={64} height={64} alt="" />
            </div>}
        </>}

        {!isLoading && !profile?.content.specialist?.avatar_moderated && appMode === AppMode.SPECIALIST && !isViewMode &&
          <div className={styles.avatar_backdrop}>
            <span className={styles.avatar_on_moderation}>{t(`${i18n_prefix}on_moderation`)}</span>
          </div>}

        {avatarIsStartUpload && <div className={styles.avatar_backdrop}>
          <span className={styles.avatar_on_moderation}>{progress}</span>
        </div>}

        {appMode === AppMode.SPECIALIST && !isViewMode &&
          <>
            {isLoading
              ? <SkeletonLayout
                width={32}
                height={32}
                borderRadius={20}
                theme="dark"
                containerClass={styles.skeleton_add_photo}
              />

              : <button
                disabled={avatarIsStartUpload}
                onClick={handleChangeAvatarClick}
                className={styles.info_content_button}>
                photo
              </button>}
          </>
        }
      </div>

      {!isLoading && (appMode === AppMode.CLIENT || isViewMode) && !!labels?.length &&
        <div className={styles.labels}><Label labels={labels} /></div>}

      <div className={styles.info_content_name}>
        {isLoading
          ? <div style={{display: "flex", justifyContent: "center"}}>
            <SkeletonLayout width={180} height={28} borderRadius={6} theme="dark" />
          </div>
          : <span className={styles.name}>
            {`${profile?.content.specialist?.first_name ?? ""} ${profile?.content.specialist?.last_name ?? ""}`}
          </span>}

        {isLoading
          ? <div className={styles.skeleton_rating}>
            <SkeletonLayout height={17} borderRadius={6} theme="dark" />
            <SkeletonLayout height={17} borderRadius={6} theme="dark" />
          </div>
          : <div className={styles.info_content_rating}>
            <Icon size={12} className="mgr-1">star_filled</Icon>

            {!!profile?.content.specialist?.nb_reviews
              ? <div className={styles.rating}>
                <span>{formatRate(profile.content.specialist?.rate)}</span>

                <span>{reviewsCount}</span>
              </div>
              : <span className={styles.newbie}>{t(`${i18n_prefix}newbie`)}</span>}
          </div>}
      </div>
    </div>

    <InAppLayout
      isOpen={uploadFailed}
      img={"/illustrations/no-conversation-3.svg"}
      title={t(`${i18n_prefix}upload_fail.title`)}
      description={t(`${i18n_prefix}upload_fail.description`)}
      onClose={toggleUploadFailedInApp}
    >
      <ButtonDefault buttonColor={ButtonColor.GRAY} buttonType={ButtonType.WRAPPED} onClick={toggleUploadFailedInApp}>
        {t(`${i18n_prefix}upload_fail.buttons.cancel`)}
      </ButtonDefault>

      <ButtonDefault hasSpace onClick={handleTryUploadAvatarAgain}>
        {t(`${i18n_prefix}upload_fail.buttons.try_again`)}
      </ButtonDefault>
    </InAppLayout>
  </div>
};

const GalleryMediaItem = ({digest, size}: { digest: string, size: number }) => {
  const currentUrl = `${process.env.REACT_APP_UPLOAD_URL}/${digest}`;

  const [loaded, onLoaded] = useState(false);

  return <Item
    original={currentUrl}
    thumbnail={imageUrl(digest, size, size, ImageResizeMode.CROP)}
    width="1600"
    height="1600"
    alt=""
    content={<ImageWithPreloader
      src={currentUrl}
      alt=""
      type="img"
    />}
  >
    {({ref, open}) => <div className={styles.preload_wrapper}>
      <picture
        style={{width: `${size}px`, height: `${size}px`}}
        ref={ref}
        onClick={open}
      >
        <img
          alt=""
          loading="lazy"
          onLoad={() => onLoaded(true)}
          src={imageUrl(digest, size, size, ImageResizeMode.CROP)}
          style={{background: !loaded ? "#EAEAEF" : ""}}
        />
      </picture>
    </div>}
  </Item>
}