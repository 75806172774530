import React, {ChangeEvent, useEffect, useRef, useState, MouseEvent} from "react";
import {useTranslation}                                              from "react-i18next";
import {useNavigate, useParams}                                      from "react-router-dom";
import {
  ButtonColor,
  ButtonType,
  ButtonDefault,
  CheckboxInput,
  GalleryMediaItem,
  GalleryWrapper,
  Icon,
  Tabs,
}                                                                    from "shared/ui";
import {
  NativeActions,
  Nullable,
  SpecialistProfilePhotosItem,
  setProfile,
  setProfileIsCashed,
}                                                                    from "shared/model";
import {
  useIntersectionObserver,
  useNativeHandler,
  useSetHeaders,
  useSkeletonThrottling,
  onParentClick,
}                                                                    from "shared/lib";
import {useProfileMediaDeleteMutation}                               from "shared/api";
import {SystemResponse, PageLayout, InAppLayout}                     from "entities/general";
import {SkeletonTabs, SkeletonLayout}                                from "entities/skeleton";
import {addNotification, AppMode, selectGalleryOpen, selectLocale}   from "store/features";
import {useAppDispatch, useAppSelector}                              from "store";
import {fetchPhotos}                                                 from "./lib/fetchPhotos";
import styles                                                        from "./ProfileMedia.module.scss";


const i18n_prefix = "page.specialist.profile.media.list."
export default function ProfileMedia() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const {id: paramId} = useParams();
  const specialistId = paramId ? parseInt(paramId) : null;
  const locale = useAppSelector(selectLocale);
  const galleryIsOpen = useAppSelector(selectGalleryOpen);

  const liRef = useRef<HTMLLIElement>(null);
  const firstRenderRef = useRef(false);
  const checkboxListRefs = useRef<HTMLInputElement[]>([]);

  const {isSuccess, appMode} = useSetHeaders();
  const {measureRef, isIntersecting, observer} = useIntersectionObserver();
  const {getTabs, tabs, getMediaList, list, listIsLoading, tabsIsLoading} = fetchPhotos({appMode});
  const [removeMedia, {isSuccess: removeMediaIsSuccess}] = useProfileMediaDeleteMutation();

  const {extendedLoading} = useSkeletonThrottling({isLoading: listIsLoading});

  const [page, setPage] = useState(1);
  const [width, setWidth] = useState(0);
  const [mediaList, setMediaList] = useState<SpecialistProfilePhotosItem[]>([]);
  const [tabId, setTabId] = useState<Nullable<number>>(null);
  const [tabCount, setTabCount] = useState(14);
  const [hasEdit, setHasEdit] = useState(false);
  const [openRemoveInApp, setOpenRemoveInApp] = useState(false);
  const [selectedMediaIds, setSelectedMediaIds] = useState<number[]>([]);

  const handleSelectMedia = (event: ChangeEvent<HTMLInputElement>) => {
    const newIds = new Set(selectedMediaIds);
    if (event.target.checked) {
      newIds.add(+event.target.value);
    } else {
      newIds.delete(+event.target.value);
    }
    setSelectedMediaIds([...newIds]);
  }

  const handleSelectAllMediaClick = () => {
    const ids = mediaList.map((_, index) => index);
    if (!selectedMediaIds.length) {
      setSelectedMediaIds(ids);
    } else {
      setSelectedMediaIds([]);
    }
  }

  const toggleHasEdit = () => {
    setHasEdit(prev => !prev);
    setSelectedMediaIds([]);
  }

  const handleToggleRemoveInApp = () => setOpenRemoveInApp(prev => !prev);

  const handleRemoveMediaClick = () => {
    const photos = mediaList.filter((_, mediaIndex) => selectedMediaIds.includes(mediaIndex)).map((item) => item.digest);
    removeMedia({photos});
  }

  const handleFetchMore = () => {
    if (!mediaList.length) return;
    if (list && !list.has_more) return;
    const currentPage = page + 1;
    setPage(currentPage);
    getMediaList({specialist_id: specialistId, page: currentPage, tab_id: tabId, limit: 30});
  }

  const handleTabClick = (id: Nullable<number>, count: number) => {
    setTabId(id);
    setMediaList([]);
    setPage(1);
    setTabCount(count);
    getMediaList({specialist_id: specialistId, tab_id: id, page: 1, limit: 30});
    if (id !== 1) {
      setHasEdit(false);
    }
  }

  const handleBackClick = () => {
    navigate(`/profile${paramId ? `/${paramId}` : ""}`);
  }

  const handleNavigateToAddFiles = () => navigate("/profile/media/add");

  const handleParentClick = (event: MouseEvent<HTMLDivElement>, index: number) => onParentClick(event, index, checkboxListRefs);

  const removeSelectedMediaDescription = locale === "ru"
    ? t(`${i18n_prefix}remove.description.value.keyWithCount`, {count: selectedMediaIds.length})
    : selectedMediaIds.length === 1
      ? t(`${i18n_prefix}remove.description.one`)
      : t(`${i18n_prefix}remove.description.many`, {count: selectedMediaIds.length});

  useEffect(() => {
    if (list?.photos) {
      setMediaList(prev => ([...prev, ...list.photos]));
    }
  }, [list?.photos, list?.has_more]);

  useEffect(() => {
    if (isSuccess && page === 1) {
      getMediaList({specialist_id: specialistId, page, limit: 30, tab_id: tabId});
    }
  }, [isSuccess, page, tabId]);

  useEffect(() => {
    if (width !== 0) return;
    const element = liRef.current;
    if (element && mediaList.length) {
      setWidth(element.offsetWidth);
    }
  }, [mediaList, width]);

  useEffect(() => {
    if (isIntersecting) {
      handleFetchMore();
      observer?.disconnect();
    }
  }, [isIntersecting]);

  useEffect(() => {
    if (isSuccess) {
      getTabs({specialist_id: specialistId});
    }
  }, [isSuccess, specialistId]);

  useEffect(() => {
    if (!firstRenderRef.current && !extendedLoading) {
      firstRenderRef.current = true;
    }
  }, [extendedLoading]);

  useEffect(() => {
    if (removeMediaIsSuccess) {
      toggleHasEdit();
      dispatch(addNotification({text: t(`${i18n_prefix}remove.success_remove`)}));
      setMediaList([]);
      getTabs({specialist_id: specialistId});
      getMediaList({specialist_id: specialistId, page: 1, tab_id: tabId, limit: 30});
      handleToggleRemoveInApp();
      dispatch(setProfile(null));
      dispatch(setProfileIsCashed(false));
    }
  }, [removeMediaIsSuccess, specialistId]);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (galleryIsOpen) return;
    return handleBackClick();
  });

  return <PageLayout
    headerTitle={!hasEdit ? t(`${i18n_prefix}header`) : t(`${i18n_prefix}remove.select_media`)}
    footer={!hasEdit ? appMode === AppMode.SPECIALIST ? <>
          <ButtonDefault
            onClick={handleNavigateToAddFiles}
            disabled={!firstRenderRef.current ? extendedLoading : tabsIsLoading}
          >
            {t(`${i18n_prefix}button`)}
          </ButtonDefault>
        </>
        : undefined
      : <div className={styles.edit_footer}>
        <ButtonDefault
          buttonRound
          buttonType={ButtonType.PLAIN}
          onClick={handleSelectAllMediaClick}
        >
          {!selectedMediaIds.length ? t(`${i18n_prefix}remove.select_all`) : t(`${i18n_prefix}remove.cancel`)}
        </ButtonDefault>

        <ButtonDefault
          buttonRound
          buttonType={ButtonType.PLAIN}
          buttonColor={ButtonColor.ERROR}
          onClick={handleToggleRemoveInApp}
          disabled={!selectedMediaIds.length}
        >
          {selectedMediaIds.length <= 1
            ? t(`${i18n_prefix}remove.delete.one`)
            : t(`${i18n_prefix}remove.delete.many`, {count: selectedMediaIds.length})}
        </ButtonDefault>
      </div>
    }
    toastPosition={82}
    leftIcon={!hasEdit ? <Icon onClick={handleBackClick}>keyboard_arrow_left</Icon> : undefined}
    rightIcon={(tabId === 1 && appMode === AppMode.SPECIALIST)
      ? <Icon onClick={toggleHasEdit}>{hasEdit ? "close" : "validation"}</Icon>
      : undefined}
    isLoading={!firstRenderRef.current ? extendedLoading : tabsIsLoading}
  >
    <>
      {(!firstRenderRef.current ? (extendedLoading && page === 1) : tabsIsLoading)
        ? <SkeletonTabs />
        : <div className={styles.tabs}>
          {tabs?.tabs.map((tab, index) => {
            return <Tabs key={index} activeTab={tabId === tab.id} onClick={() => handleTabClick(tab.id, tab.count)}>
              {`${tab.text} (${tab.count})`}
            </Tabs>
          })}
        </div>}

      {!extendedLoading && !mediaList.length && tabId === 1 && appMode === AppMode.SPECIALIST && <SystemResponse
        title={t(`${i18n_prefix}empty.title`)}
        description={t(`${i18n_prefix}empty.description`)}
        icon="image"
      />}

      <GalleryWrapper>
        <ul className={styles.wrapper}>
          {(extendedLoading && page === 1)
            ? Array.from({length: tabCount}, (_, index) => <li key={index} ref={liRef}>
              <SkeletonLayout borderRadius={8} width="100%" height="100%" containerClass={styles.skeleton_photo} />
            </li>)

            : mediaList?.map((photo, index, array) => {
              if (array.length === index + 1) {
                return <li key={index} ref={measureRef}>
                  <GalleryMediaItem
                    hasOpen={!hasEdit}
                    size={width}
                    digest={photo.digest}
                    type={photo.type}
                    reviewInfo={{createdAt: photo.created_at, rate: photo.rate, price: photo.price}}
                    description={photo.description}
                  />
                  {hasEdit && <div className={styles.media_edit} onClick={(event) => handleParentClick(event, index)}>
                    <CheckboxInput
                      name="media"
                      ref={(element) => {
                        if (element) {
                          checkboxListRefs.current[index] = element
                        }
                      }}
                      id={`${index}`}
                      value={index}
                      checkboxSize="small"
                      checked={selectedMediaIds.includes(index)}
                      onChange={handleSelectMedia}
                    />
                  </div>}
                </li>
              }
              return <li key={index} ref={liRef}>
                <GalleryMediaItem
                  size={width}
                  hasOpen={!hasEdit}
                  digest={photo.digest}
                  type={photo.type}
                  reviewInfo={{createdAt: photo.created_at, rate: photo.rate, price: photo.price}}
                  description={photo.description}
                />
                {hasEdit && <div className={styles.media_edit} onClick={(event) => handleParentClick(event, index)}>
                  <CheckboxInput
                    ref={(element) => {
                      if (element) {
                        checkboxListRefs.current[index] = element
                      }
                    }}
                    name="media"
                    value={index}
                    id={`${index}`}
                    checkboxSize="small"
                    checked={selectedMediaIds.includes(index)}
                    onChange={handleSelectMedia}
                  />
                </div>}
              </li>
            })}
        </ul>
      </GalleryWrapper>
    </>
    <InAppLayout
      isOpen={openRemoveInApp}
      img={"/illustrations/delete-4.svg"}
      title={selectedMediaIds.length === 1 ? t(`${i18n_prefix}remove.title.one`) : t(`${i18n_prefix}remove.title.many`)}
      description={removeSelectedMediaDescription}
      onClose={handleToggleRemoveInApp}
    >
      <ButtonDefault
        buttonType={ButtonType.WRAPPED}
        buttonColor={ButtonColor.GRAY}
        onClick={handleToggleRemoveInApp}
      >
        {t("common.cancel")}
      </ButtonDefault>

      <ButtonDefault
        hasSpace
        buttonColor={ButtonColor.ERROR}
        onClick={handleRemoveMediaClick}
      >
        {t("common.delete")}
      </ButtonDefault>
    </InAppLayout>
  </PageLayout>
};